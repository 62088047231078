import { Injectable, computed, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TPageData, TPagesUrls } from './staticPagesTypes';
import { TFormattedApiError } from '@utils/formattingApiError';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StaticPagesService {
  // Для футера
  #pages = signal<TPagesUrls>([]);
  pages = computed(this.#pages);

  // Для страницы регистрации
  #registrationPages = signal<TPagesUrls>([]);
  registrationPages = computed(this.#registrationPages);

  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #loadingList = signal<boolean>(false);
  loadingList = computed(this.#loadingList);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  constructor(private http: HttpClient) {}

  loadPages(is_for_registration?: boolean): void {
    this.http
      .get<TPagesUrls>(`${environment.getApiVersionUrl('pages')}`, {
        params: {
          is_for_registration: is_for_registration || '',
        },
        headers: {
          skipBearerAuthorization: 'true',
        },
      })
      .subscribe({
        next: (response) => {
          this.#pages.set(response);
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);
        },
      });
  }

  loadRegistrationPages(): void {
    this.http
      .get<TPagesUrls>(`${environment.getApiVersionUrl('pages')}`, {
        params: {
          is_for_registration: true,
        },
        headers: {
          skipBearerAuthorization: 'true',
        },
      })
      .subscribe({
        next: (response) => {
          this.#registrationPages.set(response);
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);
        },
      });
  }

  loadPageData(url: string): Observable<TPageData> {
    return this.http.get<TPageData>(
      `${environment.getApiVersionUrl('pages/url')}`,
      {
        headers: {
          skipBearerAuthorization: 'true',
        },
        params: { url },
      },
    );
  }
}
